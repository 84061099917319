import axios from "axios";

class Wishlist {
    constructor() {
        this.wishlistButton = document.querySelectorAll('.fav-product')
        this.wishlistButtonBig = document.querySelectorAll('.fav-btn')
        this.events()
    }

    events() {
        this.wishlistButton.forEach(button => {
            button.addEventListener('click', e => {
                e.preventDefault()
                this.toggleFav(button, 'fav-product--active')
            })
        })

        this.wishlistButtonBig.forEach(button => {
            button.addEventListener('click', e => {
                e.preventDefault()
                this.toggleFav(button, 'active')

            })
        })
    }

    toggleFav(button, activeClass){
        if (!button.classList.contains(activeClass)) {
            const formData = new FormData()
            formData.append('action', 'add_product_to_wishlist')
            formData.append('product_id', button.getAttribute('data-product-id'))
            this.http_request(formData).then(resp => {
                if (resp.data.success) {
                    button.classList.add(activeClass)
                }
            })
        } else {
            const formData = new FormData()
            formData.append('action', 'remove_product_from_wishlist')
            formData.append('product_id', button.getAttribute('data-product-id'))
            this.http_request(formData).then(resp => {
                if (resp.data.success) {
                    button.classList.remove(activeClass)

                }
                document.location.reload();
            })
        }

    }

    async http_request(data) {
        return await axios.post('/wp-admin/admin-ajax.php', data)
    }

}

export default Wishlist