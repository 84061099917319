import "../scss/index.scss"
import Navbar from "./modules/Navbar";
import Wishlist from "./modules/Wishlist";
import TrainingFilters from "./modules/TrainingFilters";
import FilterSlider from "./modules/FilterSlider";
import MockupSlider from "./modules/MockupSlider";
import {ratioFilterInit} from "./modules/ratioFilterInit";
import {cookiesBarInit} from "./modules/cookiesBarInit";


document.addEventListener("DOMContentLoaded", (e) => {
    const navbar = new Navbar();
    const wishlist = new Wishlist();
    const trainingFilters = new TrainingFilters();
    // const filterSlider = new FilterSlider();
    const mockupSlider = new MockupSlider();
    ratioFilterInit();
    cookiesBarInit();
})
