
export {
    cookiesBarInit
};

function cookiesBarInit() {
    if (document.cookie.indexOf("accepted_cookies=") < 0) {
        $('#cookies-bar').css('display', 'block !important');
        document.getElementById("cookies-bar").style.display = "block";
        
      } else {
          document.getElementById("cookies-bar").style.display = "none";
      }
    
      $('.accept-cookies').on('click', function() {
        document.cookie = "accepted_cookies=yes;"
        $('#cookies-bar').css('display', 'none !important');
        document.getElementById("cookies-bar").style.display = "none";
        console.log(document.cookie);
        console.log('cookie accepted')
      })
    
      // expand depending on your needs
      $('.close-cookies').on('click', function() {
        $('#cookies-bar').css('display', 'none !important');
        document.getElementById("cookies-bar").style.display = "none";
      })
}