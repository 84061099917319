class Navbar{


    constructor() {
        this.navbar = document.querySelector(".main-header__bar-wrapper");
        this.hamburger = document.querySelector(".navigation__hamburger");
        this.menu = document.querySelector(".navigation__menu");
        this.actions = document.querySelector(".navigation__actions");
        this.dropdowns = document.querySelectorAll(".navigation__item--dropdown");
        this.search = document.querySelector(".search-field")
        this.searchBox = document.querySelector(".woocommerce-product-search")
        this.events()
    }

    events(){
        this.searchBox?.addEventListener("click", (e)=>{
            e.stopPropagation()

        })
        this.search?.addEventListener("click", (e)=>{
            e.stopPropagation()
            e.preventDefault();
        })
        if(this.hamburger){
            this.hamburger.addEventListener("click", (e)=>{
                this.toggleNav();
            })
        }
        if(this.navbar){
            document.addEventListener("scroll", (e)=>{
                if(window.pageYOffset > 10 ){
                    this.navbar.classList.add("sticked")
                }else{
                    this.navbar.classList.remove("sticked")
                }
            })
        }
    }

    toggleNav(){
        if(!this.menu.classList.contains('shown')){
            this.hamburger.classList.add("open")
            this.hamburgerAnimation()

            this.menu.classList.add("shown")
            this.actions.classList.add("navigation__actions--hidden")
        }else{
            this.hamburger.classList.remove("open")
            this.hamburgerAnimation()
            this.menu.classList.remove("shown")
            this.actions.classList.remove("navigation__actions--hidden")
        }
    }

    hamburgerAnimation(){
        let rect = this.hamburger.querySelectorAll('rect');
        if(this.hamburger.classList.contains("open")){

            rect[0].setAttribute("x", '2.47949')
            rect[0].setAttribute('y', '0')
            rect[0].style.transform = 'rotate(45deg)'

            rect[1].setAttribute('x', '-50');
            rect[1].setAttribute('y', '13');


            rect[2].setAttribute('x', '-19.1621');
            rect[2].setAttribute('y', '19.1621')
            rect[2].style.transform = 'rotate(-45deg)'
        }else{

            rect[0].setAttribute("x", '0')
            rect[0].setAttribute('y', '6')
            rect[0].style.transform = 'rotate(0)'

            rect[1].setAttribute('x', '0');
            rect[1].setAttribute('y', '13');

            rect[2].setAttribute('x', '0');
            rect[2].setAttribute('y', '20')
            rect[2].style.transform = 'rotate(0)'
        }
    }


}
export default Navbar;