class MockupSlider {
    constructor() {
        this.slides = document.querySelectorAll('.mockup-slider__step')
        this.progressBars = document.querySelectorAll('.mockup-step__progress')
        this.loopOverSlides()


    }

    events() {

    }

    loopOverSlides() {
        let i = 0;
       setTimeout(()=>{
           this.animation(i)
           i++
       }, 450)
        let slide = setInterval(() => {
            if (i < this.slides.length) {
                this.animation(i)
                i++
            } else {
                clearInterval(slide)
                this.slides.forEach(el => {
                    el.classList.remove('active')
                })
                this.loopOverSlides()
            }
        }, 3150)

    }

    animation(i) {
        this.slides[i].classList.add('active')
    }


}

export default MockupSlider