import Swiper from 'swiper'
import 'swiper/css';


class FilterSlider {

    constructor() {

        this.slider = new Swiper('.swiper', {
            slidesPerView: "auto",
            centeredSlides: true,
            // width: 1000,
            initialSlide: 1,

            spaceBetween: 5,
            breakpoints: {
                780: {
                    spaceBetween: 5,
                    slidesPerView: 8,
                    centeredSlides: false,
                },
                1024: {
                    width: "auto",
                    spaceBetween: 25,
                    slidesPerView: 8,
                    centeredSlides: false,
                }
            }
        })

    }


}

export default FilterSlider