import RangeSlider from "./RangeSlider";
import FilterSlider from "./FilterSlider";
import axios from "axios";
import Swal from 'sweetalert2'

class TrainingFilters {
    constructor() {
        this.filtersWay = [];
        this.getFiltersWay();
        this.filters = ''
        this.firstStep = document.querySelector(".firstStepFilters")
        this.secondStep = document.querySelector('.secondStepFilters')
        this.filters = document.querySelectorAll(".training-filters__filter-item")
        this.voivodeships = [
            {
                title: 'dolnośląskie',
                id: 1,
            },
            {
                title: 'kujawsko-pomorskie',
                id: 2,
            },
            {
                title: 'lubelskie',
                id: 3,
            },
            {
                title: 'lubuskie',
                id: 4,
            },
            {
                title: 'łódzkie',
                id: 5,
            },
            {
                title: 'małopolskie',
                id: 6,
            },
            {
                title: 'mazowieckie',
                id: 7,
            },
            {
                title: 'opolskie',
                id: 8,
            },
            {
                title: 'podkarpackie',
                id: 9,
            },
            {
                title: 'podlaskie',
                id: 10,
            },
            {
                title: 'pomorskie',
                id: 11,
            },
            {
                title: 'śląskie',
                id: 12,
            },
            {
                title: 'świętokrzyskie',
                id: 13,
            },
            {
                title: 'warmińsko-mazurskie',
                id: 14,
            },
            {
                title: 'wielkopolskie',
                id: 15,
            },
            {
                title: 'zachodniopomorskie',
                id: 16,
            },
        ]
        this.activeFilter = ''
        this.backBtn = this.secondStep?.querySelector('.backButtonFiltersJs')
        this.sendFormBtn = this.secondStep?.querySelector('.submitFiltersJs_2')
        this.fileInput = this.secondStep?.querySelector('#files_handler')
        // this.fileInputFake = this.secondStep?.querySelector('#file_handler_fake').querySelector('.fake-handler__name')
        this.fileDelete = this.secondStep?.querySelector('.fake-handler__file-delete')
        this.dateSelected = this.secondStep?.querySelector('#date_selected')
        this.dateSelectedInput = this.secondStep?.querySelector('#date_selected_date')
        this.realizationPlace = this.secondStep?.querySelectorAll('.realization-place')
        this.firstFormValues = {
            specialisations: []
        }
        this.secondFormValues = {
            full_name: '',
            email: '',
        }

        this.cloneRealizationPlaceBtn = this.secondStep?.querySelector('.realization-place__add-btn')

        this.inputsValidate = {
            full_name: true,
            email: true,
            agreements: true
        }
        this.events()

    }

    events() {


        new RangeSlider()

        this.fileInput?.addEventListener("input", (e) => {
            this.setFileNameToShow()
        })


        this.backBtn.addEventListener("click", (e) => {
            this.firstFormValues = {
                specialisations: []
            }
            this.toggleDisplayStep(this.secondStep, true)
            this.toggleDisplayStep(this.firstStep, false)
        })

        this.dateSelected.addEventListener("input", (e) => {
            this.dateSelected.checked ? this.dateSelectedInput.disabled = false : this.dateSelectedInput.disabled = true
        })

        this.fileInput?.addEventListener("change", (e) => {
            this.fileInput.files.length > 0 ? this.fileDelete.classList.remove('d-none') : this.fileDelete.classList.add('d-none')
        })

        this.fileDelete?.addEventListener('click', (e) => {
            e.preventDefault();
            this.deleteFileFromInput()
        })

        this.sendFormBtn?.addEventListener("click", (e) => {
            e.preventDefault()
            this.sendForm()
        })

        this.cloneRealizationPlaceBtn?.addEventListener("click", this.cloneRealizationPlace)
    }

    getFilterTiles() {
        const box = document.querySelector('.tile-swiper-filters')
        let template = ''
        this.filtersWay.forEach((el, key) => {
            template += `<div class="training-filters__filter-item swiper-slide" data-sector="${key}">
                        <div class="training-filters__filter-item-img">
                            ${el.icon}
                        </div>
                        <span class="training-filters__filter-item-title">${el.category}</span>
                    </div>`
        })

        box.innerHTML = template

        new FilterSlider()
    }

    getFiltersWay() {
        // const filters = {
        //     wedding: [
        //         {
        //             title: "Fotografia",
        //             id: "fotografia",
        //             values: [
        //                 {
        //                     title: 'Sesja zdjęciowa z drona',
        //                     value: 'drone_photo_shoot'
        //                 },
        //                 {
        //                     title: 'Sesja zdjęciowa z ziemii',
        //                     value: 'ground_photo_shoot'
        //                 },
        //                 {
        //                     title: 'Obróbka zdjęć',
        //                     value: 'image_processing'
        //                 },
        //             ]
        //         },
        //         {
        //             title: "Filmowanie",
        //             id: "filmowanie",
        //             values: [
        //                 {
        //                     title: 'Sesja zdjęciowa z drona',
        //                     value: 'drone_photo_shoot'
        //                 },
        //                 {
        //                     title: 'Sesja zdjęciowa z ziemii',
        //                     value: 'ground_photo_shoot'
        //                 },
        //                 {
        //                     title: 'Montaż filmu',
        //                     value: 'film_montage'
        //                 },
        //             ]
        //         },
        //         {
        //             title: "Panorama i spacery wirtualne",
        //             id: "panorama_i_spacery",
        //             values: [
        //                 {
        //                     title: 'Zdjęcia panoramiczne z drona',
        //                     value: 'drone_photo_panoramic'
        //                 },
        //                 {
        //                     title: 'Zdjęcia panoramiczne z ziemii',
        //                     value: 'ground_photo_panoramic'
        //                 },
        //                 {
        //                     title: 'Panorama 360 z drona',
        //                     value: 'panorama_360_drone'
        //                 },
        //                 {
        //                     title: 'Panorama 360 z ziemii',
        //                     value: 'panorama_360_ground'
        //                 },
        //                 {
        //                     title: 'Montaż spaceru wirtualnego',
        //                     value: 'virtual_walk_montage'
        //                 },
        //             ]
        //         },
        //         {
        //             title: "Inspekcja",
        //             id: "inspekcja",
        //             values: [
        //                 {
        //                     title: 'Termowizja (np. wycieki, sprawność paneli fotowoltaicznych)',
        //                     value: 'termovision'
        //                 },
        //                 {
        //                     title: 'Kontrola techniczna (np. stan komina,\n' +
        //                         'dachu, rynien, słupy elektryczne',
        //                     value: 'technical_control'
        //                 },
        //             ]
        //         },
        //         {
        //             title: "Inne",
        //             id: "inne",
        //             values: [
        //                 {
        //                     title: 'Opiszę w następnym kroku',
        //                     value: 'Other'
        //                 },
        //             ]
        //         },
        //     ],
        // }

        axios.get('https://panel.bazadronow.pl/api/group-inquiry/get-specialisations', {
            headers: {
                // "Access-Control-Allow-Origin" : "*",
            }
        }).then(res => {
            this.filtersWay = res.data.data
            this.getFilterTiles()
            this.registerEventsOnFilterTiles();
        }).catch(err => {
            console.log(err)
        })


    }

    setFilters() {
        let form = document.querySelector(".training-filters__form")
        let template = `
        <div class="col-12 form-error d-none"><div>Wybierz przynajmniej 1 pole specjalizacji i 1 pole z województw</div></div>
        <div class="col-12"><h4 class="text-white training-filters__form-col-title">Zapytanie ofertowe do operatorów. Czego potrzebujesz?</h4></div>`;
        Array.from(this.filtersWay[this.activeFilter].subcategories)?.forEach((column, index) => {
            let checkboxes = '';
            column.events?.forEach((value) => {
                checkboxes += `
                       <div class="custom-checkbox-group"><label class="custom-checkbox-group__label" for="${value.event}_${value.id}">
                            <span class="custom-checkbox-group__input-wrap">
                                <input class="custom-checkbox-group__checkbox" type="checkbox" name="${column.id}" id="${value.event}_${value.id}" value="${value.id}">
                                <span class="custom-checkbox-group__mark">
                                    <svg width="16" height="13" viewBox="0 0 16 13" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1.5 7.5L5 11L14.5 1.5" stroke="#153011" stroke-width="2.5"
                                              stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                </span>
                                </span>${value.event}</label></div>
                `
            })
            template += `
         <div class="training-filters__form-col col-12 col-lg-4 col-xl-3" id="column_${column.subcategory}">
                    <h4 class="text-white training-filters__form-col-title">${column.subcategory}</h4>
                    ${checkboxes}
                </div>
        `
        })

        let voivodeshipRow = `
        <div class="col-12"><h4 class="text-white training-filters__form-col-title">Obszar zlecenia:</h4></div>
        `
        let chunkStart = 0;
        let chunkEnd = 4;
        for (let i = 0; i < 4; i++) {
            let checkboxes = '';

            let checkboxesChunk = this.voivodeships.slice(chunkStart, chunkEnd)


            checkboxesChunk.forEach((chunk, index) => {
                checkboxes += `
                <div class="custom-checkbox-group"><label class="custom-checkbox-group__label" for="'voivodeship_${chunk.id}">
                            <span class="custom-checkbox-group__input-wrap">
                                <input class="custom-checkbox-group__checkbox" type="checkbox" name="voivodeship" id="'voivodeship_${chunk.id}" value="${chunk.id}">
                                <span class="custom-checkbox-group__mark">
                                    <svg width="16" height="13" viewBox="0 0 16 13" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1.5 7.5L5 11L14.5 1.5" stroke="#153011" stroke-width="2.5"
                                              stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                </span>
                                </span>${chunk.title}</label></div>`
            })

            chunkStart = chunkStart + 4;
            chunkEnd = chunkEnd + 4;

            voivodeshipRow += ` 
                <div class="training-filters__form-col col-12 col-lg-4 col-xl-3">
               
                       ${checkboxes}
                </div>`


        }

        let btn = `
        <div class="col-12 d-flex justify-content-center justify-content-lg-start pt-5 training-filters__button-row">
         <button type="submit" class="button proper-button proper-button--white submitFiltersJs">
            Dalej
        </button>
        </div>
        `
        template += voivodeshipRow
        template += btn
        form.innerHTML = template
        let submitBtn = form.querySelector('.submitFiltersJs')
        submitBtn?.addEventListener("click", (e) => {
            e.preventDefault()
            let formData = new FormData(document.forms.filters_form_checkboxes)

            Array.from(this.filtersWay[this.activeFilter].subcategories).forEach((el, index) => {
                formData.getAll(el.id).length > 0 ? this.firstFormValues.specialisations[index] = {
                    categoryId: this.activeFilter,
                    subcategory: {
                        id: el.id,
                        values: formData.getAll(el.id)
                    }
                } : ""
            })

            this.firstFormValues.province = formData.getAll('voivodeship').length > 0 ? formData.getAll('voivodeship') : ''

            let errorRow = this.firstStep.querySelector('.form-error')
            if (this.checkLength(this.firstFormValues.specialisations, 1) && this.checkLength(this.firstFormValues.province, 1)) {
                // this.getBaner()
                !errorRow.classList.contains('d-none') ? errorRow.classList.add('d-none') : ""
                this.toggleDisplayStep(this.firstStep, true)
                this.toggleDisplayStep(this.secondStep, false)
            } else {
                errorRow.classList.contains('d-none') ? errorRow.classList.remove('d-none') : ""
            }

        })


    }

    toggleDisplayStep(step, hide) {
        if (hide) {
            !step.classList.contains("d-none") ? step.classList.add("d-none") : step.classList.remove("d-none")
        } else {
            step.classList.contains("d-none") ? step.classList.remove("d-none") : step.classList.add("d-none")
        }
    }

    deleteFileFromInput() {
        this.fileInput.value = ""
        this.setFileNameToShow()
        this.fileDelete.classList.add('d-none')

    }

    setFileNameToShow() {
        this.fileInputFake.innerText = this.fileInput.files.length > 0 ? this.fileInput.files[0].name : "Wybierz plik"
    }

    registerEventsOnFilterTiles() {
        this.filters = document.querySelectorAll(".training-filters__filter-item")

        this.filters.forEach((filter) => {
            filter.addEventListener("click", (e) => {
                this.filters.forEach(filter => filter.classList.contains('training-filters__filter-item--active') ? filter.classList.remove('training-filters__filter-item--active') : "")
                if (!filter.classList.contains("training-filters__filter-item--active")) {
                    filter.classList.add('training-filters__filter-item--active')
                    this.activeFilter = filter.getAttribute('data-sector')
                    this.setFilters();
                } else {
                    filter.classList.remove('training-filters__filter-item--active')
                }
            })
        })
    }

    // getBaner() {
    //     axios.post('https://bazadronow.klastbr.ayz.pl/group-inquiry/get-banner', JSON.stringify(this.firstFormValues)).then((res => {
    //         let baner = JSON.parse(res.data.data)
    //     })).catch(err => {
    //         console.log(err)
    //     })
    // }

    sendForm() {

        let form = document.querySelector("#filter_data_form");


        [...form.elements].forEach((item) => {
            if (item.type === "radio" && item.checked) {
                this.secondFormValues[item.name] = item.value
            } else if (item.type === "radio" && !item.checked) {
                return
            } else if (item.type === "checkbox") {
                item.checked ? this.secondFormValues[item.name] = true : this.secondFormValues[item.name] = false
            } else {
                this.secondFormValues[item.name] = item.value
            }
        });


        for (const [key, value] of Object.entries(this.secondFormValues)) {
            if (key === "full_name") {
                this.inputsValidate[key] = this.checkLength(value, 5)
            }
            if (key === "email") {
                this.inputsValidate[key] = this.checkLength(value, 5) && this.validateEmail(value)
            }
            if (key === "agreements") {
                this.inputsValidate[key] = value
            }
        }


        for (const [key, value] of Object.entries(this.inputsValidate)) {
            let errorMsg = this.secondStep.querySelector(`.form-error__${key}`)
            if (value === false) {
                errorMsg.classList.contains("d-none") ? errorMsg.classList.remove('d-none') : ""
            } else {
                !errorMsg.classList.contains("d-none") ? errorMsg.classList.add('d-none') : ""
            }


        }

        if (!Object.keys(this.inputsValidate).some(k => !this.inputsValidate[k])) {

            let formValue = {
                specialisations: this.checkLength(this.firstFormValues.specialisations.length, 0) ? this.firstFormValues.specialisations : "",
                province: this.checkLength(this.firstFormValues.province.length, 0) ? this.firstFormValues.province : "",
                personalData: {
                    fullName: this.secondFormValues.full_name,
                    email: this.secondFormValues.email,
                    companyName: this.secondFormValues["company_name"],
                    phoneNumber: this.secondFormValues["phone_number"],
                },
                address: [
                    {
                        street: this.secondFormValues.street,
                        buildNumber: this.secondFormValues["house_number"],
                        postalCode: this.secondFormValues.postcode,
                        city: this.secondFormValues.city,

                    }
                ],
                reservationDate: {
                    type: this.secondFormValues.appointment,
                    date: this.secondFormValues['appointment-date'],
                },
                budget: {
                    type: this.secondFormValues.budget,
                    from: this.secondFormValues.budget === "budget_range" ? this.secondFormValues["price_from"] : "",
                    to: this.secondFormValues.budget === "budget_range" ? this.secondFormValues["price_to"] : ""
                },
                attachment: {
                    description: this.secondFormValues['added_message']
                }

            }

            let form = document.querySelector(".training-filters__form")
            let form_sec = document.querySelector("#filter_data_form")
            axios.post('https://panel.bazadronow.pl/api/group-inquiry/send-form', JSON.stringify(formValue))
            form.reset();
            form_sec.reset();
            Swal.fire({
                title: `<h2 class="popup__title">Dziękujemy!</h2>`,
                text: `Twoje zapytanie zostało wysłane do operatorów. Potencjalni realizatorzy odezwą się do Ciebie drogą mailową.`,
                buttonsStyling: false,
                confirmButtonText: "Odlot!",
                customClass: {
                    confirmButton: 'button proper-button proper-button--blue text-white proper-button--wide',
                    htmlContainer: 'form-popup__text',
                    actions: 'form-popup__actions',
                    popup: 'form-popup'
                }
            }).then(result => {
                result.isConfirmed ? window.location.reload() : ""
            })
        } else {

            return
        }


    }

    //validation

    checkLength(el, minLength) {
        if (el.length < minLength) {
            return false
        } else {
            return true
        }
    }

    validateEmail(email) {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    cloneRealizationPlace() {
        this.realizationPlace = document.querySelectorAll('.realization-place')
        let template = `
           <div class="row filter-form__realization-place realization-place " id="realization_${this.realizationPlace.length + 1}">
                <div class="col-12 d-flex align-items-center realization-place__header">
                    <h4 class="text-white">Miejsce realizacji ${this.realizationPlace.length + 1}</h4>
                    <span data-delete="realization_${this.realizationPlace.length + 1}" class="delete_rp">❌</span>
                </div>
                <div class="col-lg-6 col-12">
                    <div class="filter-form__input-group">
                        <label class="filter-form__label" for="street_${this.realizationPlace.length + 1}">Ulica</label>
                        <input class="filter-form__text-input" name="street_${this.realizationPlace.length + 1}" id="street_${this.realizationPlace.length + 1}" type="text"
                               placeholder="Nazwa uilcy"/>
                    </div>
                    <div class="filter-form__input-group">
                        <label class="filter-form__label" for="city_${this.realizationPlace.length + 1}">Miejscowość</label>
                        <input class="filter-form__text-input" name="city_${this.realizationPlace.length + 1}" id="city_${this.realizationPlace.length + 1}" type="text"
                               placeholder="np. Lublin"/>
                    </div>
                </div>
                <div class="col-lg-6 col-12">
                    <div class="filter-form__input-group">
                        <label class="filter-form__label" for="house_number_${this.realizationPlace.length + 1}">Nr domu/lokalu</label>
                        <input class="filter-form__text-input" name="house_number_${this.realizationPlace.length + 1}" id="house_number_${this.realizationPlace.length + 1}" type="text"
                               placeholder="np. 12/4"/>
                    </div>
                    <div class="filter-form__input-group">
                        <label class="filter-form__label" for="postcode_${this.realizationPlace.length + 1}">Kod pocztowy</label>
                        <input class="filter-form__text-input" name="postcode_${this.realizationPlace.length + 1}" id="postcode_${this.realizationPlace.length + 1}" type="text"
                               placeholder="00 - 000"/>
                    </div>
                </div>
            </div>`

        this.realizationPlace[this.realizationPlace.length - 1].insertAdjacentHTML("afterend", template);
        let deleteBtn = document.querySelectorAll('.delete_rp')
        deleteBtn?.forEach(btn => {
            btn.addEventListener('click', (e) => {
                let id = btn.dataset.delete
                let el = document.querySelector('#' + id)
                el ? el.remove() : "";
            })
        })
    }


}

export default TrainingFilters