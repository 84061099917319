
class RangeSlider {
    constructor() {
        const fromSlider = document.querySelector('#fromSlider');
        const toSlider = document.querySelector('#toSlider');
        this.fromPrice = document.querySelector('#price_from')
        this.toPrice = document.querySelector('#price_to')
        this.fillSlider(fromSlider, toSlider, '#C6C6C6', '#ffffff', toSlider);
        this.setToggleAccessible(toSlider);

        fromSlider.oninput = () => this.controlFromSlider(fromSlider, toSlider);
        toSlider.oninput = () => this.controlToSlider(fromSlider, toSlider);

    }

    controlFromSlider(fromSlider, toSlider, fromInput) {
        const [from, to] = this.getParsed(fromSlider, toSlider);
        this.fillSlider(fromSlider, toSlider, '#B4D7EB', '#ffffff', toSlider);
        this.fromPrice.innerText = from
        if (from > to) {
            fromSlider.value = to;
        }
    }
    controlToSlider(fromSlider, toSlider) {

        const [from, to] = this.getParsed(fromSlider, toSlider);
        this.fillSlider(fromSlider, toSlider, '#B4D7EB', '#ffffff', toSlider);
        this.setToggleAccessible(toSlider);
        this.toPrice.innerText = to
        if (from <= to) {
            toSlider.value = to;
        } else {
            toSlider.value = from;
        }
    }

    getParsed(currentFrom, currentTo) {
        const from = parseInt(currentFrom.value, 10);
        const to = parseInt(currentTo.value, 10);
        return [from, to];
    }

    fillSlider(from, to, sliderColor, rangeColor, controlSlider) {
        const rangeDistance = to.max-to.min;
        const fromPosition = from.value - to.min;
        const toPosition = to.value - to.min;
        controlSlider.style.background = `linear-gradient(
      to right,
      ${sliderColor} 0%,
      ${sliderColor} ${(fromPosition)/(rangeDistance)*100}%,
      ${rangeColor} ${((fromPosition)/(rangeDistance))*100}%,
      ${rangeColor} ${(toPosition)/(rangeDistance)*100}%, 
      ${sliderColor} ${(toPosition)/(rangeDistance)*100}%, 
      ${sliderColor} 100%)`;
    }

    setToggleAccessible(currentTarget) {
        const toSlider = document.querySelector('#toSlider');
        if (Number(currentTarget.value) <= 0 ) {
            toSlider.style.zIndex = 2;
        } else {
            toSlider.style.zIndex = 0;
        }
    }
}



export default RangeSlider;