
export {
    ratioFilterInit
};

function ratioFilterInit() {

    $('.budgetRatio').click(function() {
        $(this).attr( 'checked', true )
        if($('#budget_estimate').is(':checked')) { 
            $('.sliders_control input').attr('disabled', true );
        }
        if($('#budget_range').is(':checked')) { 
            $('.sliders_control input').attr('disabled', false );
        }
     });
}